import React from 'react';

const SampleComponent: React.FC = () => {
    return (
        <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
            <div className="flex-shrink-0">
                <img className="h-12 w-12" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" />
            </div>
            <div>
                <div className="text-xl font-medium text-black">TailwindUI</div>
                <p className="text-gray-500">React + TypeScript + TailwindCSS</p>
            </div>
        </div>
    );
};

export default SampleComponent;
